.text-editor-2 {
    background-color: #282c34;
    min-height: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-bottom: 5vh;
    text-align: center;
  }
  
  .wrapper-class {
    padding: 1rem;
    border: 1px solid #eee;
  }
  
  .editor-class {
    /* background-color:lightgray; */
    background-color:#fff;
    padding: 1rem;
    border: 1px solid #ccc; 
    min-height: 50vh;
  }
  
  .toolbar-class {
    border: 1px solid #ccc;
  }
  
  .preview {
    padding: 1rem;
    margin-top: 1rem;
  }