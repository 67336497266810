.page-footer {
    margin-top: -25px;
}
html {margin: 0; height: 100%}
body {margin: 0; height: 100%}
* {box-sizing: border-box;}

.fab:focus{
    color: green;
    background-color:yellow;
}

.dabutton:focus {
    background-color:yellow;
}

.fab .fa-facebook:focus {
    background-color:yellow !important;
    }