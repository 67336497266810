



  
  @media only screen and (max-width: 376px) {
    .nw2-provider {
        /* background: #F5F5F5 0% 0% no-repeat padding-box; */
        background-image: linear-gradient(to top, #F5F5F5, #FFF,#FFF);
        border-radius: 35px;
        opacity: 1;
        padding:0;
        margin-bottom:-180px
    }
  
   
    
  }


  @media only screen and (min-width: 376px) and (max-width: 576px) {
    .nw2-provider {
        /* background: #F5F5F5 0% 0% no-repeat padding-box; */
        background-image: linear-gradient(to top, #F5F5F5, #FFF,#FFF);
        border-radius: 35px;
        opacity: 1;
        padding:0;
        margin-bottom:0px
    }
  
   
    
  }
  
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    
    .nw2-provider {
        /* background: #F5F5F5 0% 0% no-repeat padding-box; */
        background-image: linear-gradient(to top, #F5F5F5, #FFF,#FFF);
        border-radius: 35px;
        opacity: 1;
        padding:0;
        margin-bottom:10px
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .nw2-provider {
        /* background: #F5F5F5 0% 0% no-repeat padding-box; */
        background-image: linear-gradient(to top, #F5F5F5, #FFF,#FFF);
        border-radius: 35px;
        opacity: 1;
        padding:0;
        margin-bottom:10px
    }
  }
  
  @media only screen and (min-width: 992px) {
    .nw2-provider {
        /* background: #F5F5F5 0% 0% no-repeat padding-box; */
        background-image: linear-gradient(to top, #F5F5F5, #FFF,#FFF);
        border-radius: 35px;
        opacity: 1;
        padding:0;
        margin-bottom:10px
    }
  
   
  }
  