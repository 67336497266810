.product-nw1-category-header {
    background-color: #000000;
    overflow: hidden;
  }
  
  .product input[type="text"] {
    background: transparent;
    border: none;
    border-bottom: 1px solid #ffffff;
    width: 150px;
    color: #ffffff;
    outline: none;
    padding: 2px;
    border-radius: 2px;
  }
  
  .product input[type="text"]:hover {
    width: 200px;
    border: none;
    border-bottom: 1px solid #ffffff;
    outline: none;
    padding: 2px;
  }
  .product input[type="text"]:focus:hover {
    outline: none;
  
    border-radius: 0;
    width: 200px;
    
  }
  
  select {
    width: 132px;
    padding: 2px;
    border: none;
    outline: none;
  
    -webkit-appearance: none;
  }
  
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff;
    opacity: 1; /* Firefox */
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .nav {
    background-color: red;
    color: white;
  }
  
  .nav span {
    font-weight: bold;
  }

  
  
 
    .nw1-product-smaller-header {
      background: #008D4B;
      height: 10vh;
      width: 100%;

      display: flex;
    
      color: white;
      position: absolute;
      top: 0;
      padding-left: 3.8em;
    }
  
    .nw1-product-bigger-header {
      background-color: #c1de76;
      height: 35vh;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
    
    }
  
    .product-sell {
      font-size: 0.4em;
    }
    .wakanda-logo-div {
      position: relative;
    }
  
  